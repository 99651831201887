import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaCopy } from 'react-icons/fa';
import '../assets/Dashboard.css';
import SideBar from '../components/SideBar.jsx';
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import { getAuthUser } from '../utils/auth';
import useInactivityLogout from '../hooks/useInactivityLogout.js';

const Dashboard = () => {
    useInactivityLogout(2880); // Call the hook with a 30-minute or 2880 minutes (2 days) timeout
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Added state for sidebar
    
    const [areAccountsVisible, setAreAccountsVisible] = useState(false);

    const user = getAuthUser();
    //console.log(user.data);

    const accounts = [
        { number: "**********", bank: "Moniepoint" },
        { number: "**********", bank: "Wema Bank" },
        { number: "**********", bank: "Providus" }
    ];

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    

    const toggleAccountsVisibility = () => {
        setAreAccountsVisible(!areAccountsVisible);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }).catch(err => {
            alert('Failed to copy: ' + err);
        });
    };

    return (
        <div className="dashboard-container">
            <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> {/* Pass state and function to Sidebar */}

            <div className="main-content">
                <Header title="Dashboard" />
                {user.data.user.kyc_status === 1 && (
                <div className="account-section">
                    <button className="toggle-accounts-button" onClick={toggleAccountsVisibility}>
                        My Bank Accounts &nbsp;&nbsp;&nbsp;&nbsp;{areAccountsVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                    {areAccountsVisible && (
                        <div className="account-cards">
                            {accounts.map((account, index) => (
                                <div className="account-card" key={index}>
                                    <p className="account-number">{account.number}</p>
                                    <p className="account-bank">{account.bank}</p>
                                    <FaCopy size={20} className="copy-icon" onClick={() => copyToClipboard(account.number)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                )}
                {user.data.user.kyc_status === 1 && (
                <div className="transactionTable">
                    <table border="1" align="center">
                        <thead>
                          <tr><th colSpan="4">Recent Transactions</th></tr>
                          <tr>
                            <th style={{ width: '20px' }}>S/N</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Topup Account Moniepoint</td>
                            <td>21, June, 2024</td>
                            <td align="right">2,000</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Buy 20G Data</td>
                            <td>22, June, 2024</td>
                            <td>6,000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Buy 20G Data</td>
                            <td>22, June, 2024</td>
                            <td>6,000</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Buy 20G Data</td>
                            <td>22, June, 2024</td>
                            <td>6,000</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Buy 20G Data</td>
                            <td>22, June, 2024</td>
                            <td>6,000</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                )}
            </div>

            <div className="footer">
                <Footer></Footer>
            </div>
        </div>
    );
};

export default Dashboard;
