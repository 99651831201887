import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import '../assets/Login.css';
import email_icon from '../assets/img/email.png';
import password_icon from '../assets/img/password.png';
import logo from '../assets/img/haske260.PNG';
import eye_icon from '../assets/img/eye.png'; // Import the eye icon
import eye_slash_icon from '../assets/img/eye-slash.png'; // Import the eye slash icon
import { getAuthUser } from '../utils/auth';
import Swal from 'sweetalert2';

const Login = () => {
    // State for password visibility
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate input on change
    if (errors[name]) {
      validateField(name, value);
    }
  };

  // Validate individual fields
  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        fieldErrors.email = value
          ? emailRegex.test(value)
            ? ''
            : 'Invalid email address'
          : 'Email is required';
        break;
      case 'password':
        fieldErrors.password = value ? '' : 'Password is required';
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  // Validate entire form
  const validateForm = () => {
    let formErrors = {};

    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = 'Invalid email address';
      }
    }
    if (!formData.password) formErrors.password = 'Password is required';
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading
      try {
        const response = await fetch(`${API_BASE_URL}user/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const user = await response.json(); // Get user data from response
          localStorage.setItem('authUser', JSON.stringify(user));
          navigate(`/dashboard`); // Redirect to Dashboard
        } else {
            // Display SweetAlert for failed login
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: 'Please check your email and password and try again.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Try Again'
            });
        }
      } catch (error) {
        // Display SweetAlert for network or server errors
        Swal.fire({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'Please try again later.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
        });
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };


  return (
    <>
      <div className="container">
        <div className="logo"><img src={logo} alt="Logo" /></div>
        <div className="header-page">
          <div className="text">Login</div>
          <div className="underline"></div>
        </div>
        <div className="inputs">
        <div className="input">
            <img src={email_icon} alt="Email Icon" />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              onBlur={() => validateField('email', formData.email)}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="input">
            <img src={password_icon} alt="Password Icon" />
            <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              onBlur={() => validateField('password', formData.password)}
            />
            <img
              src={passwordVisible ? eye_slash_icon : eye_icon}
              alt="Toggle Password Visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="toggle-password"
            />
            {errors.password && <span className="error">{errors.password}</span>}
          </div>
        </div>
        <div className="forgot-password">Lost Password? <span><Link to="/forgotpassword">click here</Link></span></div>
        <div className="submit-container">
          <div className="submit">
          <div
                className={`submit ${loading ? 'disabled' : ''}`}
                onClick={handleSubmit}
                >
                {loading ? (
                    <>
                    <div className="spinner"></div>
                    <span>login...</span>
                    </>
                ) : (
                    'Login'
                )}
            </div>
          </div>
        </div>
        <div className="login-signup">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </div>
      </div>
      <div className="footer"><p>powered by: AITS HUB &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;support line: 08037397040, 08136098764</p></div>
    </>
  );
}

export default Login;
