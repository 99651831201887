import React,{useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import '../assets/Login.css';
import email_icon from '../assets/img/email.png';
import logo from '../assets/img/haske260.PNG';
import { getAuthUser } from '../utils/auth';

const ForgotPassword = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);
  return (
    <>
      <div className="container">
        <div className="logo"><img src={logo} alt="Logo" /></div>
        <div className="header-page">
          <div className="text">Forgot Password</div>
          <div className="underline"></div>
        </div>
        <div className="inputs">
          <div className="input">
            <img src={email_icon} alt="Email Icon" />
            <input type="email" placeholder="Email" />
          </div>
          
        </div>
        
        <div className="submit-container">
          <div className="submit">Send</div>
        </div>
        <div className="login-signup">
            Already have an account? <Link to="/signup">Login</Link>
        </div>
      </div>
      <div className="footer"><p>powered by: AITS HUB&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;support line: 08037397040, 08136098764</p></div>
    </>
  );
}

export default ForgotPassword;
