import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
import SuccessPage from './pages/SuccessPage.jsx';
import Dashboard from './pages/Dashboard.jsx';
import UpdateProfile from './pages/UpdateProfile.jsx';
import SignUp from './pages/SignUp.jsx';
import TransactionHistory from './pages/TransactionHistory.jsx';
import BuyData from './pages/BuyData.jsx';
import BuyAirtime from './pages/BuyAirtime.jsx';
import BuyElectricity from './pages/BuyElectricity.jsx';
import BuyCableTv from './pages/BuyCableTv.jsx';
import ProtectedRoute from './pages/ProtectedRoute.js';
import Kyc from './pages/Kyc.jsx';

const App = () => { 
  return (
    <Router>
      <Routes>
      <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/successpage" element={<SuccessPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/kyc"
          element={
            <ProtectedRoute>
              <Kyc />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-profile"
          element={
            <ProtectedRoute>
              <UpdateProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transaction-history"
          element={
            <ProtectedRoute>
              <TransactionHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/buy-data"
          element={
            <ProtectedRoute>
              <BuyData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/buy-airtime"
          element={
            <ProtectedRoute>
              <BuyAirtime />
            </ProtectedRoute>
          }
        />
        <Route
          path="/buy-cable"
          element={
            <ProtectedRoute>
              <BuyCableTv />
            </ProtectedRoute>
          }
        />
        <Route
          path="/buy-electricity"
          element={
            <ProtectedRoute>
              <BuyElectricity />
            </ProtectedRoute>
          }
        />
        
        <Route path="/" element={<Login />} /> {/* Default route */}
      </Routes>
    </Router>
  );
}

export default App;
