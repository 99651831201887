import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt, FaChevronDown } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import '../assets/KycAlert.css';
import { getAuthUser } from '../utils/auth';

const Header = ({ title }) => {
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const [authUser, setAuthUser] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const accountBalance = 0;

    const handleLogout = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}user/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.token}`,
                },
            });

            if (response.ok) {
                localStorage.removeItem('authUser');
                navigate('/login');
                Swal.fire({
                    icon: 'success',
                    title: 'Logout',
                    text: 'You are successfully logged out',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
            } else {
                alert('Logout failed. Please try again.');
            }
        } catch (error) {
            console.error('Error logging out:', error);
            alert('An error occurred during logout. Please try again.');
        }
    };

    useEffect(() => {
        const user = getAuthUser();
        if (user) {
            setAuthUser(user.data);
        } else {
            navigate('/login');
        }
    }, [navigate]);

    if (!authUser) {
        return <div>Loading...</div>;
    }

    const handleAvatarClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleKycRedirect = () => {
        navigate('/kyc');
    };

    // Extract the image URL from the passport object
    const passportUrl = authUser.user.passport || ''; // Adjust this based on the actual structure of passport object
    
    return (
        <div className="header">
            <div className="user-info">
                <div className="user-details">
                    <h2>Hi {authUser.user.username}!</h2>
                </div>
                <div className="user-avatar-container">
                    {passportUrl ? (
                        <img 
                            src={passportUrl} 
                            alt="User Avatar" 
                            className="profile-picture" 
                            onClick={handleAvatarClick} 
                        />
                    ) : (
                        <FaUserCircle size={40} className="user-avatar" onClick={handleAvatarClick} />
                    )}
                    <FaChevronDown size={20} className={`dropdown-arrow ${isDropdownVisible ? 'rotate' : ''}`} onClick={handleAvatarClick} />
                    {isDropdownVisible && (
                        <div className="dropdown-menu">
                            <button className="dropdown-item" onClick={handleLogout}>
                                <FaSignOutAlt size={20} /> Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="text">{title}</div>
            <div className="underline"></div>
            {authUser.user.kyc_status === 1 ? (
                currentLocation.pathname !== '/update-profile' && (
                <div>
                    <button className="balance-button">
                        My Wallet Balance: <strong>N{accountBalance.toFixed(2)}</strong>
                    </button>
                </div>
                )
            ) : (
                currentLocation.pathname !== '/kyc' && (
                    <div className="kyc-alert">
                        <button className="danger-button" onClick={handleKycRedirect}>
                            KYC Verification Required - Click to Proceed
                        </button>
                    </div>
                )
            )}
        </div>
    );
};

export default Header;
