import React, { useState } from 'react';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../assets/Dashboard.css';
import '../assets/Kyc.css';
import SideBar from '../components/SideBar.jsx';
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import Swal from 'sweetalert2';
import { getAuthUser } from '../utils/auth';


const Kyc = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    //const user = JSON.parse(localStorage.getItem('authUser'));
    const user = getAuthUser();
    //console.log(user.data);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Form state
    const [formData, setFormData] = useState({
        bvn: '',
        nin: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate(); // Initialize useNavigate

    // Handle input changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      // Validate input on change
      if (errors[name]) {
        validateField(name, value);
      }
    };

    // Validate individual fields
    const validateField = (name, value) => {
      let fieldErrors = { ...errors };

      switch (name) {
        case 'bvn':
          fieldErrors.bvn = value ? '' : 'BVN is required';
          break;
        default:
          break;
      }

      setErrors(fieldErrors);
    };

    // Validate entire form
    const validateForm = () => {
      let formErrors = {};

      if (!formData.bvn) formErrors.bvn = 'BVN is required';
      
      setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async () => {
      if (validateForm()) {
        setLoading(true); // Start loading
        try {
          const response = await fetch(`${API_BASE_URL}user/verify-kyc`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.data.token}`,
            },
            body: JSON.stringify(formData),
          });

          const data = await response.json(); // Parse the response

          if (response.ok) {
              // Update kyc_status in localStorage
              const updatedUser = {
                  ...user,
                  data: {
                    ...user.data,
                    user: {
                        ...user.data.user,
                        kyc_status: 1, // Set kyc_status to 1
                    },
                  },
              };
              // Save the updated user object back to localStorage
              localStorage.setItem('authUser', JSON.stringify(updatedUser));
              
              navigate(`/dashboard`); // Redirect to dashboard
              Swal.fire({
                  icon: 'success',
                  title: 'KYC Verification',
                  text: 'KYC verification Successfully Submitted',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
              });
          } else {

              let errorMessage;
              if (data.data && typeof data.data === 'object') {
                  errorMessage = Object.keys(data.data)
                    .map((key) => data.data[key].join(' '))
                    .join(' ');
              } else if (typeof data.data === 'string') {
              errorMessage = data.data; // Use the string directly
              } else {
              errorMessage = 'KYC failed. Please try again.';
              }

              Swal.fire({
                  icon: 'error',
                  title: 'KYC Verification Failed',
                  text: errorMessage,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Try Again'
              });
          }
        } catch (error) {
          // Display SweetAlert for network or server errors
          Swal.fire({
              icon: 'error',
              title: 'An Error Occurred',
              text: 'Please try again later. ',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Try Again'
          });
        } finally {
          setLoading(false); // Stop loading
        }
      }
    };

    return (
        <div className="dashboard-container">
            <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            <div className="main-content">
                <Header title="KYC Verification" />

                <div className="kycForm">
                    <div>
                        <div className="form-group">
                            <label htmlFor="bvn">BVN (required)</label>
                            <input 
                                type="text" 
                                name="bvn" 
                                id="bvn" 
                                placeholder="Enter your BVN" required 
                                value={formData.bvn}
                                onChange={handleChange}
                                onBlur={() => validateField('bvn', formData.bvn)}
                            />
                            {errors.bvn && <span className="error">{errors.bvn}</span>}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="nin">NIN (optional)</label>
                            <input 
                                type="text" 
                                name="nin" 
                                id="nin" 
                                placeholder="Enter your NIN" required 
                                value={formData.nin}
                                onChange={handleChange}
                                onBlur={() => validateField('nin', formData.nin)}
                            />
                        </div>
                        
                        <div className="form-group">
                            <button 
                                type="submit" 
                                className={`submit-button ${loading ? 'disabled' : ''}`}
                                onClick={handleSubmit}
                            >
                               {loading ? (
                                    <>
                                    <div className="spinner"></div>
                                    <span>Submitting KYC...</span>
                                    </>
                                ) : (
                                    'Submit Kyc'
                                )} 
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

export default Kyc;
